import HomePage from './views/homePage';
import OurSolutions from './views/ourSolutions';
import WeAssist from './views/weAssist';
import AboutPage from './views/aboutPage';
import NewsPage from './views/newsPage';
import NewsDetails from './views/newsDetails';
import ArticlesPage from './views/articlesPage';
import ArticlesDetails from './views/articlesDetails';
import ContactPage from './views/contactPage';
import GdprPage from './views/gdprPage';
import CustomersTellUs from './views/customersTellUs';
import PartnersPage from './views/partnersPage';
import CookiesPage from './views/cookiesPage';
import PrivacyPolicy from './views/privacyPolicy';
import Unsubscirbe from './views/unsubscirbe'
import DownloadAPP from './views/downloadApp';
import ThankYou from "./views/thankYou"
import { API_ENDPOINT } from './constants';
import { HTML_CHARACTERS } from './htmlCharacters';
import contactPageBookaDemo from './views/contactPageBookaDemo';
import Integrations from './views/integrationsPage'
import FormSubmittedPage from './views/formSubmittedPage';
import serviceDescriptionPage from './views/serviceDescriptionPage';

const seoImage = require('./assets/images/Curoflow telemedicine platform - a white-label healthcare operating system.jpg')

const replaceCharacters = (value) => {
    if (value) {
        for (var key in HTML_CHARACTERS) {
            value = value.replace(new RegExp(key, 'g'), HTML_CHARACTERS[key])
        }
    }
    return value;
}

//site: true,
const linkTranslates = {

    'en': {
        '/': '/en',
        '/vi-hjalper/:alias': '/en/we-assist/:alias',
        // '/vara-losningar/:alias': '/en/our-solution/:alias',
        '/var-plattform/:alias': '/en/our-solution/:alias',

        '/integrationer/:alias': '/en/integrations/:alias',
        '/foretaget/:alias': '/en/company/:alias',
        '/nyheter-om-curoflow-vardplattform': '/en/news-about-the-curoflow-practice-management-software',
        // '/nyheter-om-curoflow-vardplattform/:alias': '/en/news-about-the-curoflow-healthcare-platform/:alias',
        '/artiklar-om-mjukvara-for-digital-vard/:alias': '/en/articles-about-digital-healthcare/:alias',
        '/artiklar-om-mjukvara-for-digital-vard': '/en/articles-about-digital-healthcare',
        '/gdpr-efterlevnad-i-Curoflow-digital-vardplattform': '/en/gdpr-compliance-in-Curoflow-practice-management-software',
        '/kunders-feedback-pa-Curoflow-digital-vardplattform': '/en/customer-feedback-on-Curoflow-practice-management-software',
        '/app': '/en/app',
        // '/for-partners': '/en/for-partners',
        '/cookies': '/en/cookies',
        '/integritetspolicy': '/en/privacy-policy',
        '/kontakta-oss': '/en/contact-us',
        '/thank-you': '/en/thank-you',
        '/unsubscribe/:email': '/en/unsubscribe/:email',
        '/form-submitted': '/form-submitted',
        '/:alias': '/en/:alias',
        '/boka-demo': '/en/book-demo',
        "/tjanstebeskrivning": "/en/service-description"

    },
    // 'se': {
    // '/': '/',
    //     '/weassist/:alias': '/vi-hjalper/:alias',
    //     '/our-solution/:alias': '/vara-losningar/:alias',
    //     '/about-us/:alias': '/om-oss/:alias',
    //     '/nyheter-om-curoflow-vårdplattform': '/se/news-about-the-curoflow-healthcare-platform',
    //     '/news-details': '/specifik-nyhet',
    //     '/gdpr': '/en/gdpr',
    //     '/customers-tell-us': '/kunder-berattar',
    //     '/app': '/se/app',
    //     '/for-partners': '/se/for-partners',
    //     '/cookies': '/se/cookies',
    //     '/integritetspolicy': '/integritetspolicy',
    //     '/kontakta-oss': '/kontakta-oss',
    //     '/tack': '/se/thank-you',
    //     '/unsubscribe/:email':'/unsubscribe/:email',

    // },
}
var striptags = require('striptags');


function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}

export const routes = [
    {

        path: "/",
        sitemap: true,
        generateSeoTags: (data, lang) => {
            console.log('data: ', data);
            console.log('lang', lang)
            if (data && data.result) {
                return {
                    title: Object.translate(data.result[0], 'title', lang),
                    description: Object.translate(data.result[0], 'description', lang),
                    tags: Object.translate(data.result[0], 'tags', lang),
                    'og:title': Object.translate(data.result[0], 'title', lang),
                    'og:description': Object.translate(data.result[0], 'description', lang),
                    'og:type': Object.translate(data.result[0], 'type', lang),
                    'og:url': Object.translate(data.result[0], 'url', lang),
                    'og:image': API_ENDPOINT + data.result[0].image
                }
            } else {
                return {

                }
            }
        },
        component: HomePage,
        loadData: [

        ],
        loadDataWithQuery: [

            // (fetchFunction, lang, match, query) => {
            //     return fetchFunction(API_ENDPOINT + '/data/pages/all-home', {
            //         method: 'GET',
            //         headers: {
            //             'content-type': 'application/json'
            //         },
            //     }).then(parseJSON).then(({ result, status }) => {
            //         if (result)
            //             return {
            //                 homePage: result

            //             }
            //     })

            // },

            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/pages/all-home-seo-tag', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    return {
                        result: result,
                    }
                })
            }
        ]
    },

    {
        path: "/gdpr-efterlevnad-i-Curoflow-digital-vardplattform",
        sitemap: true,
        generateSeoTags: (data, lang) => {
            if (data && data.result) {
                return {
                    title: Object.translate(data.result[0], 'title', lang),
                    description: Object.translate(data.result[0], 'description', lang),
                    tags: Object.translate(data.result[0], 'tags', lang),
                    'og:title': Object.translate(data.result[0], 'title', lang),
                    'og:description': Object.translate(data.result[0], 'description', lang),
                    'og:type': Object.translate(data.result[0], 'type', lang),
                    'og:url': Object.translate(data.result[0], 'url', lang),
                    'og:image': API_ENDPOINT + data.result[0].image
                }
            } else {
                return {

                }
            }
        },
        component: GdprPage,
        loadData: [

        ],
        loadDataWithQuery: [

            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/pages/all-gdpr', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (result && result.length && result[0].section)
                        return {
                            gdprPage: result[0].section
                        }
                })
            },

            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/pages/all-gdpr-seo-tag', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    return {
                        result: result,
                    }
                })
            }
        ]
    },

    {
        path: '/kunders-feedback-pa-Curoflow-digital-vardplattform',
        sitemap: true,
        generateSeoTags: (data, lang) => {
            if (data) {

                return {
                    title: Object.translate(data, 'customersTellUs.seotag', lang),
                    description: replaceCharacters(striptags(Object.translate(data, 'customersTellUs.seoDescription', lang))),
                    'og:title': Object.translate(data, 'customersTellUs.seotag', lang),
                    'og:description': replaceCharacters(striptags(Object.translate(data, 'customersTellUs.seoDescription', lang))),

                }
            } else {
                return {

                }
            }
        },
        component: CustomersTellUs,
        loadData: [

        ],
        loadDataWithQuery: [

            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/pages/all-customers-tell-us', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {

                    if (result && result.length && result[0].section)
                        return {
                            customersTellUs: result[0]
                        }
                })
            },
        ]
    },

    // {
    //     path: "/for-partners",
    //     sitemap: true,
    //     generateSeoTags: (data, lang) => {
    //         if (data && data.result) {
    //             return {
    //                 title: Object.translate(data.result[0], 'title', lang),
    //                 description: replaceCharacters(Object.translate(data.result[0], 'description', lang)),
    //                 tags: Object.translate(data.result[0], 'tags', lang),
    //                 'og:title': Object.translate(data.result[0], 'title', lang),
    //                 'og:description': Object.translate(data.result[0], 'description', lang),
    //                 'og:type': Object.translate(data.result[0], 'type', lang),
    //                 'og:url': Object.translate(data.result[0], 'url', lang),
    //                 'og:image': API_ENDPOINT + data.result[0].image
    //             }
    //         } else {
    //             return {

    //             }
    //         }
    //     },
    //     component: PartnersPage,
    //     loadData: [

    //     ],
    //     loadDataWithQuery: [

    //         (fetchFunction, lang, match, query) => {
    //             return fetchFunction(API_ENDPOINT + '/data/pages/all-forpartners', {
    //                 method: 'GET',
    //                 headers: {
    //                     'content-type': 'application/json'
    //                 },
    //             }).then(parseJSON).then(({ result, status }) => {
    //                 if (result && result.length && result[0].section)
    //                     return {
    //                         forPartners: result[0].section
    //                     }
    //             })
    //         },

    //         (fetchFunction, lang, match, query) => {
    //             return fetchFunction(API_ENDPOINT + '/data/pages/all-for-partners-seo-tag', {
    //                 method: 'GET',
    //                 headers: {
    //                     'content-type': 'application/json'
    //                 },
    //             }).then(parseJSON).then(({ result, status }) => {
    //                 return {
    //                     result: result,
    //                 }
    //             })
    //         }
    //     ]
    // },
    {
        path: "/app",
        generateSeoTags: (data, lang) => {
            if (data && data.result) {
                return {
                }
            } else {
                return {
                    title: 'App',
                }
            }
        },
        component: DownloadAPP,
        loadData: [

        ],
        loadDataWithQuery: [
        ]
    },
    {
        path: "/cookies",
        sitemap: true,
        generateSeoTags: (data, lang) => {
            if (data && data.result) {
                return {
                    title: Object.translate(data.result[0], 'title', lang),
                    description: replaceCharacters(Object.translate(data.result[0], 'description', lang)),
                    tags: Object.translate(data.result[0], 'tags', lang),
                    'og:title': Object.translate(data.result[0], 'title', lang),
                    'og:description': Object.translate(data.result[0], 'description', lang),
                    'og:type': Object.translate(data.result[0], 'type', lang),
                    'og:url': Object.translate(data.result[0], 'url', lang),
                    'og:image': API_ENDPOINT + data.result[0].image
                }
            } else {
                return {

                }
            }
        },
        component: CookiesPage,
        loadData: [

        ],
        loadDataWithQuery: [

            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/pages/all-coockies', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    return {
                        cookiesPage: result
                    }
                })
            },

            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/pages/all-coockies-seo-tag', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    return {
                        result: result,
                    }
                })
            }
        ]

    },
    {
        path: "/tjanstebeskrivning",
        sitemap: true,
        generateSeoTags: (data, lang) => {
            console.log('lang: ', lang)
            return {
                title: lang === 'en' ? 'Service Description' : 'Tjänstebeskrivning',
                'og:title': lang === 'en' ? 'Service Description' : 'Tjänstebeskrivning',
                noIndex: true,
                'og:description': 'Curoflow',
                description: 'Curoflow',
            }
            if (data && data.result) {
                return {
                    title: Object.translate(data.result[0], 'title', lang),
                    description: replaceCharacters(Object.translate(data.result[0], 'description', lang)),
                    tags: Object.translate(data.result[0], 'tags', lang),
                    'og:title': Object.translate(data.result[0], 'title', lang),
                    'og:description': Object.translate(data.result[0], 'description', lang),
                    'og:type': Object.translate(data.result[0], 'type', lang),
                    'og:url': Object.translate(data.result[0], 'url', lang),
                    'og:image': API_ENDPOINT + data.result[0].image
                }
            } else {
                return {

                }
            }
        },
        component: serviceDescriptionPage,
        loadData: [

        ],
        loadDataWithQuery: [

            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/pages/all-service-description', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    return {
                        serviceDescriptionPage: result
                    }
                })
            },

            // (fetchFunction, lang, match, query) => {
            //     return fetchFunction(API_ENDPOINT + '/data/pages/all-coockies-seo-tag', {
            //         method: 'GET',
            //         headers: {
            //             'content-type': 'application/json'
            //         },
            //     }).then(parseJSON).then(({ result, status }) => {
            //         return {
            //             result: result,
            //         }
            //     })
            // }
        ]

    },

    {
        path: "/integritetspolicy",
        sitemap: true,
        generateSeoTags: (data, lang) => {
            if (data && data.result) {
                return {
                    title: Object.translate(data.result[0], 'title', lang),
                    description: replaceCharacters(Object.translate(data.result[0], 'description', lang)),
                    tags: Object.translate(data.result[0], 'tags', lang),
                    'og:title': Object.translate(data.result[0], 'title', lang),
                    'og:description': Object.translate(data.result[0], 'description', lang),
                    'og:type': Object.translate(data.result[0], 'type', lang),
                    'og:url': Object.translate(data.result[0], 'url', lang),
                    'og:image': API_ENDPOINT + data.result[0].image
                }
            } else {
                return {

                }
            }
        },
        component: PrivacyPolicy,
        loadData: [

        ],
        loadDataWithQuery: [

            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/pages/all-privatepolicy', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    return {
                        privacyPolicy: result
                    }
                })
            },


            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/pages/all-privacy-policy-seo-tag', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    return {
                        result: result,
                    }
                })
            }
        ]
    },

    {
        path: '/kontakta-oss',
        sitemap: true,
        generateSeoTags: (data, lang) => {
            if (data && data.result) {
                return {
                    title: Object.translate(data.result[0], 'title', lang),
                    description: Object.translate(data.result[0], 'description', lang),
                    tags: Object.translate(data.result[0], 'tags', lang),
                    'og:title': Object.translate(data.result[0], 'title', lang),
                    'og:description': Object.translate(data.result[0], 'description', lang),
                    'og:type': Object.translate(data.result[0], 'type', lang),
                    'og:url': Object.translate(data.result[0], 'url', lang),
                    'og:image': API_ENDPOINT + data.result[0].image
                }
            } else {
                return {

                }
            }
        },
        component: ContactPage,
        loadData: [

        ],
        loadDataWithQuery: [

            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/pages/all-contact', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    return {
                        contactUs: result
                    }

                })

            },

            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/pages/all-contact-seo-tag', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    return {
                        result: result,
                    }
                })
            }
        ]
    },
    {
        path: '/boka-demo',
        sitemap: true,
        generateSeoTags: (data, lang) => {
            if (data && data.result) {
                return {
                    title: Object.translate(data.result[0], 'title', lang),
                    description: Object.translate(data.result[0], 'description', lang),
                    tags: Object.translate(data.result[0], 'tags', lang),
                    'og:title': Object.translate(data.result[0], 'title', lang),
                    'og:description': Object.translate(data.result[0], 'description', lang),
                    'og:type': Object.translate(data.result[0], 'type', lang),
                    'og:url': Object.translate(data.result[0], 'url', lang),
                    'og:image': API_ENDPOINT + data.result[0].image
                }
            } else {
                return {

                }
            }
        },
        component: contactPageBookaDemo,
        loadData: [

        ],
        loadDataWithQuery: [

            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/pages/all-contact', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    return {
                        contactUs: result
                    }

                })

            },

            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/pages/all-contact-seo-tag', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    return {
                        result: result,
                    }
                })
            }
        ]
    },
    {
        path: "/thank-you",
        component: ThankYou,
        loadData: [

        ],
        loadDataWithQuery: [

        ]
    },

    {
        path: '/vi-hjalper/:alias',
        sitemap: true,
        generateSeoTags: (data, lang) => {
            if (data && data.pageData) {
                return {
                    title: Object.translate(data, 'pageData.seotag', lang),
                    description: replaceCharacters(striptags(Object.translate(data, 'pageData.seoDescription', lang))),
                    'og:title': Object.translate(data, 'pageData.seotag', lang),
                    'og:description': replaceCharacters(striptags(Object.translate(data, 'pageData.seoDescription', lang))),

                }
            } else {
                return {

                }
            }
        },
        component: WeAssist,
        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/pages/all-weassist/' + match.params.alias, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    return {
                        pageData: result
                    }

                })

            },

        ]
    },
    {
        path: '/integrationer/:alias',
        sitemap: true,
        generateSeoTags: (data, lang) => {
            if (data && data.pageData) {
                return {
                    title: Object.translate(data, 'pageData.seotag', lang),
                    description: replaceCharacters(striptags(Object.translate(data, 'pageData.seoDescription', lang))),
                    'og:title': Object.translate(data, 'pageData.seotag', lang),
                    'og:description': replaceCharacters(striptags(Object.translate(data, 'pageData.seoDescription', lang))),

                }
            } else {
                return {

                }
            }
        },
        component: Integrations,
        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/pages/all-integrations/' + match.params.alias, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    return {
                        pageData: result
                    }

                })

            },

        ]
    },
    {
        path: "/nyheter-om-curoflow-vardplattform",
        generateSeoTags: (data, lang) => {
            // console.log(data, 'data', data.result);
            if (data && data.result) {
                return {
                    title: Object.translate(data.result[0], 'seotag', lang),
                    description: replaceCharacters(Object.translate(data.result[0], 'seoDescription', lang)),
                    'og:title': Object.translate(data.result[0], 'seotag', lang),
                    'og:description': replaceCharacters(Object.translate(data.result[0], 'seoDescription', lang)),

                }
            } else {
                return {

                }
            }
        },
        component: NewsPage,
        loadData: [

        ],

        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }
                return fetchFunction(API_ENDPOINT + '/data/pages/all-news', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields

                    })
                }).then(parseJSON).then(({ result, status }) => {
                    return {
                        articles: result.items,
                        totalArticles: result.total
                    }

                })

            },

            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/pages/all-news-as-seo-tag', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    return {
                        result: result,
                    }
                })
            }
        ]
    },

    {
        path: "/artiklar-om-mjukvara-for-digital-vard",
        generateSeoTags: (data, lang) => {
            // console.log(data);
            if (data && data.result) {
                return {
                    title: Object.translate(data.result[0], 'seotag', lang),
                    description: replaceCharacters(Object.translate(data.result[0], 'seoDescription', lang)),
                    'og:title': Object.translate(data.result[0], 'seotag', lang),
                    'og:description': replaceCharacters(Object.translate(data.result[0], 'seoDescription', lang)),

                }
            } else {
                return {

                }
            }
        },
        component: ArticlesPage,
        loadData: [

        ],

        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {
                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }
                return fetchFunction(API_ENDPOINT + '/data/pages/all-articles', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields

                    })
                }).then(parseJSON).then(({ result, status }) => {
                    // console.log(result);
                    return {
                        articles: result.items,
                        totalArticles: result.total
                    }

                })

            },

            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/pages/all-articles-as-seo-tag', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    return {
                        result: result,
                    }
                })
            }
        ]
    },

    {
        path: '/var-plattform/:alias',
        sitemap: true,
        generateSeoTags: (data, lang) => {
            if (data && data.pageData) {
                return {
                    title: Object.translate(data, 'pageData.seotag', lang),
                    description: replaceCharacters(striptags(Object.translate(data, 'pageData.seoDescription', lang))),
                    'og:title': Object.translate(data, 'pageData.seotag', lang),
                    'og:description': replaceCharacters(striptags(Object.translate(data, 'pageData.seoDescription', lang))),
                }
            } else {
                return {

                }
            }
        },
        component: OurSolutions,
        loadData: [

        ],
        loadDataWithQuery: [

            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/pages/all-solution/' + match.params.alias, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    return {
                        pageData: result
                    }

                })

            },




        ]
    },
    {
        path: '/foretaget/:alias',
        sitemap: true,
        generateSeoTags: (data, lang) => {
            if (data) {
                return {
                    title: Object.translate(data, 'pageData.seotag', lang),
                    description: replaceCharacters(striptags(Object.translate(data, 'pageData.seoDescription', lang))),
                    'og:title': Object.translate(data, 'pageData.seotag', lang),
                    'og:description': replaceCharacters(striptags(Object.translate(data, 'pageData.seoDescription', lang))),
                }
            } else {
                return {

                }
            }
        },
        component: AboutPage,
        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/pages/all-aboutus/' + match.params.alias, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    // console.log(result);
                    return {
                        pageData: result
                    }

                })

            },
        ]
    },

    {
        path: "/unsubscribe/:email",
        component: Unsubscirbe,
        loadData: [

        ],
        loadDataWithQuery: [

        ]
    },
    {
        path: '/artiklar-om-mjukvara-for-digital-vard/:alias',
        sitemap: true,
        newsSiteMap: true,
        generateSeoTags: (data, lang) => {
            // console.log('aaaaaaaaaaaa',data);
            if (data && data.result) {
                console.log('sdasdads', data.result.descriptionSeoArticle);
                return {
                    title: Object.translate(data.result, 'titleSeoArticle', lang),
                    description: replaceCharacters(striptags(Object.translate(data.result, 'descriptionSeoArticle', lang))),
                    'og:title': Object.translate(data.result, 'title', lang),
                    'og:description': replaceCharacters(striptags(Object.translate(data.result, 'descriptionSeoArticle', lang))),
                }
            } else {
                return {

                }
            }

        },
        component: ArticlesDetails,
        loadData: [

        ],
        loadDataWithQuery: [

            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/pages/all-articles-details-as-seo-tag/' + match.params.alias, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    return {
                        result: result,
                    }
                })
            }
        ]
    },

    {
        path: '/form-submitted',
        sitemap: true,
        newsSiteMap: true,
        generateSeoTags: () => ({
            title: 'Form Submitted',
            description: '',
            'og:title': 'Form Submitted',
            'og:description': ''
        }),
        // generateSeoTags: (data, lang) => {

        //     if (data && data.result) {
        //         console.log('sdasdads', data.result.descriptionSeoArticle);
        //         return {
        //             title: Object.translate(data.result, 'titleSeoArticle', lang),
        //             description: replaceCharacters(striptags(Object.translate(data.result, 'descriptionSeoArticle', lang))),
        //             'og:title': Object.translate(data.result, 'title', lang),
        //             'og:description': replaceCharacters(striptags(Object.translate(data.result, 'descriptionSeoArticle', lang))),
        //         }
        //     } else {
        //         return {

        //         }
        //     }

        // },
        component: FormSubmittedPage,
        loadData: [

        ],
        loadDataWithQuery: [

            // (fetchFunction, lang, match, query) => {
            //     return fetchFunction(API_ENDPOINT + '/data/pages/all-articles-details-as-seo-tag/' + match.params.alias, {
            //         method: 'GET',
            //         headers: {
            //             'content-type': 'application/json'
            //         },
            //     }).then(parseJSON).then(({ result, status }) => {
            //         return {
            //             result: result,
            //         }
            //     })
            // }
        ]
    },

    {
        path: '/:alias',
        sitemap: true,
        newsSiteMap: true,
        generateSeoTags: (data, lang) => {
            // console.log('test seo', data.result, 'jezik  ', lang);
            if (data && data.result) {
                return {
                    title: Object.translate(data.result, 'titleSeoNews', lang),
                    description: replaceCharacters(striptags(Object.translate(data.result, 'descriptionSeoNews', lang))),
                    'og:title': Object.translate(data.result, 'title', lang),
                    'og:description': replaceCharacters(striptags(Object.translate(data.result, 'descriptionSeoNews', lang))),
                }
            } else {
                return {

                }
            }

        },
        component: NewsDetails,
        loadData: [

        ],
        loadDataWithQuery: [

            (fetchFunction, lang, match, query) => {
                // console.log('alias',lang, match, query);
                // console.log(API_ENDPOINT + `/data/pages/all-news-details-as-seo-tag/${match.params.alias}`);
                if (match.params && match.params.alias)
                    return fetchFunction(API_ENDPOINT + `/data/pages/all-news-details-as-seo-tag/${match.params.alias}`, {
                        method: 'GET',
                        headers: {
                            'content-type': 'application/json'
                        },
                    }).then(parseJSON).then(({ result, status }) => {
                        // console.log(result, 'results news seo');
                        return {
                            result: result,
                        }
                    })
            }
        ]
    },



];

export function generateRoutes(r, lang) {
    let newRoutes = [];
    for (let i = 0; i < r.length; i++) {
        newRoutes.push({ ...r[i], ssrLang: 'se' });

        newRoutes.push({
            ...r[i],
            path: linkTranslates[`en`][r[i].path],
            ssrLang: 'en'
        })


        // if(/*lang && lang === 'se' &&*/ linkTranslates['se'] && linkTranslates['se'][r[i].path]){
        //     newRoutes.push({
        //         ...r[i],
        //         path: linkTranslates[`se`][r[i].path],
        //         ssrLang: 'se'
        //     })
        // }else if(/*lang && lang === 'en' &&*/linkTranslates['en'] && linkTranslates['en'][r[i].path]){
        //     newRoutes.push({
        //         ...r[i],
        //         path: linkTranslates[`en`][r[i].path],
        //         ssrLang: 'en'
        //     })
        // }

    }
    return newRoutes;
}